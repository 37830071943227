#backgroundImage, #firstSection {
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
}

#firstSection {
	background-image: url(../img/CoupleRunning.jpg);
}

#secondSection {
	background-color: $LightBlue;
}

/* FEEDBACK */
table#recentFeedback tbody td {
	padding: 0 .2em;
}

table#recentFeedback .button {
	margin: 0;
}

/* Inspirations */
ul.accordion li a:not(.button) {
	font-size: 1em;
	color: $Aquamarine;
}

.accordion-title {
	background-color: $light-gray;
}
.accordion-content, .accordion-title {
	border-color: $dark-gray !important;
}
.accordion-title:hover, .accordion-title:focus {
	background-color: $medium-gray;
}

#inspirations .date {
	text-transform: uppercase;
	color: $alert-color;
	font-weight: bold;
}

#inspirations p {
	font-size: .9em;
}

/* CONSISTENCY */
.stat .smallerText {
	font-size: .4em;
}

/* HOME */
#funStuff .circleBG {
	margin: 0 auto -25px auto;
	border-radius: 50%;
	height: 100px;
	width: 100px;
	padding-top: 15px;
	border: 5px solid $medium-gray;
	background-color: $light-gray;
	z-index: 1000;
	position: relative;
}

#funStuff .card {
	border: 5px solid $medium-gray;	
}

#funStuff .fontAwesomeIcon i {
	padding-top: 12px;
	color: $success-color;
	font-size: 2em;
}
/* Calendar */

#calendar ul {
	list-style-type: none;
}

/* Month header */
#calendar .month {
    padding: 10px 25px;
    width: 100%;
    background: $Aquamarine;
}

/* Month list */
#calendar .month ul {
    margin: 0;
    padding: 0;
}

#calendar .month ul li {
    color: white;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 3px;
}


/* Weekdays (Mon-Sun) */
#calendar .weekdays {
    margin: 0;
    padding: 10px 0;
    background-color:$light-gray;
}

#calendar .weekdays li {
    display: inline-block;
    width: 13.6%;
    color: $black;
    text-align: center;
}

/* Days (1-31) */
#calendar .days {
    padding: 10px 0;
    background: $medium-gray;
    margin: 0;
}

#calendar .days li {
    list-style-type: none;
    display: inline-block;
    width: 13.6%;
    text-align: center;
    margin-bottom: 5px;
    color: $dark-gray;
}

/* Highlight the "current" day */
#calendar .days li.success {
    padding: 5px;
    background: $success-color;
    color: white !important
}
#calendar .days li.today {
    padding: 5px;
    border: 1px solid $Aquamarine;
}

/* HEADER */
header {
	border-bottom: 1px solid $Aquamarine;
}
.menu-text {
	color: $white !important;
}
.menu-text span {
	font-weight: normal;	
}

#topBarMenu .top-bar-right ul li a:not(.button), #topBarMenu .top-bar-right ul li a:link:not(.button), #topBarMenu .top-bar-right ul li a:visited:not(.button) {
	color: $Aquamarine;
}

#topBarMenu .top-bar-left ul li a:not(.button), #topBarMenu .top-bar-left ul li a:link:not(.button), #topBarMenu .top-bar-left ul li a:visited:not(.button) {
	color: $white;
}

#topBarMenu .top-bar-right ul li a:hover:not(.button), #topBarMenu .top-bar-right ul li a:active:not(.button),
#topBarMenu .top-bar-left ul li a:hover:not(.button), #topBarMenu .top-bar-left ul li a:active:not(.button) {
	color: $medium-gray;
}

#logoMenu {
	background-color: $white;
}
#main-menu ul:not(.submenu)>li>a {
	padding: 31px 0;
}
#main-menu ul>li>a {
	color: $Aquamarine;	
}
#main-menu ul:not(.submenu)>li>a, #main-menu ul:not(.submenu)>li>a:link, #main-menu ul:not(.submenu)>li>a:visited {
	border-bottom: 5px solid $white;
}

#main-menu ul:not(.submenu)>li>a:hover, #main-menu ul:not(.submenu)>li>a:active {
	border-bottom: 5px solid $Aquamarine;
}
#main-menu ul.submenu {
	border-top: 5px solid $Aquamarine;
}
#main-menu ul.submenu li a {
	border-bottom: 1px solid $medium-gray;
}

#main-menu ul.submenu li:hover {
	background-color: $medium-gray;
}

#mobileMenu, #mobileMenu .menu-icon {
	background: $white !important;
	color: $Aquamarine;
}
#mobileMenu .menu-icon:after {
	display: none;
}

/* REPORT */
#reportNav ul.menu li {
  padding:5px;
 }
 
/* FORM */
[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'], .dec.small, .inc.small {
	border-radius: 0;
	height: 2em !important;
}
textarea {
	border-radius: 0;
}

[type='file'] {
	font-family: $body-font-family;
	background: $success-color;
	color: #ffffff;
}

.helpMessage {
	font-size: rem-calc(14);
	color: $dark-gray;
	padding-left: 5px;
}
.buttonHelpMessage {
	font-size: rem-calc(13);
	display: block;	
	padding-top: 5px; 
}

/* STAR RATING */
/* jQuery.Rating Plugin CSS - http://www.fyneworks.com/jquery/star-rating/ */
div.rating-cancel,div.star-rating {
	float: left;
	width: 35px;
	height: 29px;
	text-indent: -999em;
	cursor: pointer;
	display: block;
	background: transparent;
	overflow: hidden
}

div.rating-cancel,div.rating-cancel a {
	background: url(../star-rating/delete.png) no-repeat 0 -30px
}

div.star-rating,div.star-rating a {
	background: url(../star-rating/star.png) no-repeat 0 0px
}

div.rating-cancel a,div.star-rating a {
	display: block;
	width: 30px;
	height: 100%;
	background-position: 0 0px;
	border: 0
}

div.star-rating-on a {
	background-position: 0 -30px !important
}

div.star-rating-hover a, div.star-rating a:hover{
	background-position: 0 -60px
}
/* Read Only CSS */
div.star-rating-readonly a {
	cursor: default !important
}
/* Partial Star CSS */
div.star-rating {
	background: transparent !important;
	overflow: hidden !important
}
/* END jQuery.Rating Plugin CSS */

/* cakePHP Rating Helper CSS - http://techblog.pierrekrohmer.de */

.hover-box {
	margin-left: 20px;
	margin-top: 19px;
	text-transform: uppercase;
	font-size: 12px;
}

.star-rating-element{

}

/* END cakePHP Rating Helper CSS */


/* FORMS */
.switch_label {padding-left: $switch-label-spacing;}
.smaller-label i {font-size: .74em;}

/* LAYOUT */
.space_between {margin-top: $space-between;}

/* FOOTER */
footer {
	background-color: $FooterBG;
	color: #ffffff;
	background-image: url(../img/ActiveSilhouettesImprint.png);
	min-height: 149px;
}
footer a, footer a:link, footer a:visited {
	color: #ffffff;
}
footer a:hover, footer a:active {
	color: $medium-gray;
}


/* Small only */
@media screen and #{breakpoint(small down)} {
	.magellan .menu li a span {
		display: none;
	}
	.menu-text {
		width: 100%;
		text-align: center;
		margin-bottom: 15px;
	}
	#headerLogo {
		padding-top: .3em;
		padding-left: .7em;
		padding-right: .7em;
		width: 99%;
	}
	#main-menu ul:not(.submenu)>li>a {
		padding: .5em 0;
	}
	table#recentFeedback .smallerFont{
		font-size: .8em;
		line-height: .8em;
	}
	
}